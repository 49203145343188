import React from 'react';
import '../../App.css';
import '../Cards.css';
import HeroSectionServices from '../HeroSection_Services';
import CardItem from '../CardItem';
import DoneIcon from '@mui/icons-material/Done';

export default function Services() {
  return (
    <>
    <HeroSectionServices />

    {/* <div className='about'>
        <h1>Services</h1>
        <br/>
        <p>
        Our state of the art live scan fingerprinting system can digitally scan and capture your fingerprints, and then immediately transmit them electronically to the California State Department of Justice in Sacramento. You can walk in for service any time during business hours without an appointment. If you are located in San Mateo, Fremont, Burlingame, Redwood City, Foster City, Campbell, Union City, Pleasanton, Livermore, or any other location, you can also benefit from our mobile live scan fingerprint service.
        </p>
    </div> */}

<div className='aboutus'>
  <div className='head'>
      <h1>Live Scan</h1>
    </div>
  </div>
    <div className='service'>
    {/* <h1>Services</h1> */}
        <p>Our fingerprinting service encompasses both electronic and traditional methods of capturing and submitting fingerprints.</p>
        <div className='Grid'>
          <div className='left'>
            <img style={{borderRadius:'10px'}} src='/images/dig-scan.jpg' />
          </div>
          <div className='right'>
            <div className='inner-content'>
                <h4>Computer Based Live Scan Fingerprint</h4>
                <p>The Live Scan fingerprinting system involves capturing fingerprints directly into a digital format, replacing traditional ink and paper methods.
                   These systems have advanced from standalone devices to integrated systems connected to secure databases. Images can be captured, transmitted, 
                   and interfaced with Automated Fingerprint Identification Systems (AFIS) for identification processing. 
                   This method is cleaner, faster, and less prone to errors compared to traditional methods.</p>
            </div>
          </div>
        </div>
        <div className='Grid'>
          <div className='left'>
          <div className='inner-content'>
  <h4>Ink Fingerprint</h4>
  <ul>
    <li>Ink fingerprints are also known as "hard card" fingerprints or FBI FD-258 card fingerprints.</li>
    <li>We stock a substantial supply of authentic FBI FD-258 fingerprint cards.</li>
    <li>If you don't have your own fingerprint cards, we can provide them to you free of charge.</li>
    <li>The U.S. Department of Justice provides guidelines for individuals seeking a copy of their FBI Identification Record.</li>
    <li>These requests are handled by the FBI's Criminal Justice Information Services (CJIS) Division.</li>
  </ul>
</div>

          </div>
          <div className='right'>
            <img style={{borderRadius:'10px'}} src='/images/ink-scan.jpg' />
          </div>
        </div>
      </div>
      {/* <div className='about'>
        <h1>Fees</h1>
        <br/>
        <p>
        Our transparent fee structure ensures you have a clear understanding of the costs involved. Our fingerprint rolling fees is only $14.99. Additional processing fees are required for state Department of Justice (DOJ) and FBI level criminal history records checks. Other fees may also be required.
        </p>
      </div> */}
    <div className='cards'>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardItem
                src='images/icons/walk-in.png'
                title='Credit Card'
                text='Credit Card on File is an option as well as Employee Pays Special Discount Pricing. Contact us for details.'
              />
              <CardItem
                src='images/icons/booking.png'
                title='Our Specialties'
                text='We specialize in assisting HR departments with fingerprint background checks, helping to alleviate their workload. 
        Our commitment to excellence has led us to be selected by State Agencies for beta testing new programs before implementation. 
        Rest assured, we adhere strictly to all laws, policies, and procedures in alignment with law enforcement processes.'
              />
              <CardItem
                src='images/icons/help.png'
                title='Expertise'
                text='we are the finger printing experts, so you don’t have to worry about a thing! We provide 5-star hospitality customer service, 
      ensuring a seamless experience for our clients. With our industry expertise, we aim to simplify your tasks by proactively handling background follow-ups, relieving HR of unnecessary burdens. 
      Our efficient service ensures that customers are in and out in minutes!'
              />
            </ul>
            <ul className='cards__items'>
              <CardItem
                src='images/icons/certificate.png'
                title='Notary'
                text='Ensure the authenticity of your important documents with our professional notary services. Our experienced notaries are here to assist you with legalizing, 
                certifying, and validating documents. Trust us for secure and reliable notary services.'
                path='https://www.mailallcenter.com/'
              />
              <CardItem
                src='images/icons/passport.png'
                title='Passport'
                text='Embark on your next adventure hassle-free! Our passport services are designed to make the application and renewal process seamless. 
                Let us handle the details, so you can focus on planning your travels. Your passport journey starts here!'
                path='https://www.mailallcenter.com/'
              />
              <CardItem
                src='images/icons/delivery.png'
                title='Pack & Ship'
                text='Reliable packing and shipping solutions tailored to your needs. Whether it is a small package or a larger shipment, 
                we ensure your items reach their destination safely and on time. Ship with confidence, entrust your parcels to us!'
                path='https://www.mailallcenter.com/'
              />
            </ul>
          </div>
        </div>
      </div>
      <div className='customers'>
      <h2>Our Services Across the Bay Area</h2>
      <p>We proudly offer a wide range of professional services including Live Scan fingerprinting, Notary Public, Apostille, Passport Photos & Renewal, Mailbox Rental, and Pack & Ship at the following locations:</p>
      <div className='list'>
          <div className='col-0'>
          <ul>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Palo Alto</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Sunnyvale</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Los Altos</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Cupertino</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Menlo Park</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Santa Clara</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; San Jose</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Milpitas</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Redwood City</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Mountain View</li>
          </ul>
          </div>
          <div className="col-0">
            <ul>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Fremont</li>
            
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Campbell</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Saratoga</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Morgan Hill</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Atherton</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Belmont</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; San Mateo</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Half Moon Bay</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; Woodside</li>
            <li><DoneIcon sx={{color:'green'}}/>&nbsp; East Palo Alto</li>
            </ul>
          </div>
        </div>
    </div>
    <footer className="footer">
        <h2>Payment options</h2>
        <p>Upon completion of the Services Agreement, we offer monthly invoicing for your convenience. Following this, we will provide your company with a payment voucher, authorizing the fees to be charged to your account.</p>
      </footer>
    </>
  );
}