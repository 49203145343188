import React from "react";
import "../Apostilles.css"; // Create a CSS file for styling
import "../../App.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';

const ApostilleServices = () => {
  return (
    <>
    <div className='service'>
      <div className='Grid'>
          <div className='left'>
            <img style={{borderRadius:'10px', maxHeight:'500px', maxWidth:'500px'}} src='/images/fingerprint-1.jpg' />
          </div>
          <div className='right'>
            <div className='inner-content'>
                <h1>Simplifying the Apostille Process</h1>
                <p>At Live Scan Solutions, we ensure your documents receive the certification they need without delays.</p>
                <h4> Services We Offer</h4>
                {/* <ListItemButton component="a" href="https://www.mailallcenter.com/Notary-Public">
                  <ListItemText primary="Notary Public" />
                </ListItemButton> */}

                <a href='/' target='_blank'><span><DoneIcon sx={{color:'green'}}/>&nbsp;Live Scan</span></a>
                <a href='https://www.mailallcenter.com/Notary-Public' target='_blank'><span><DoneIcon sx={{color:'green'}}/>&nbsp;Notary Public</span></a>
                <a href='https://www.mailallcenter.com/Passport-Photos' target='_blank'><span><DoneIcon sx={{color:'green'}}/>&nbsp;Passport Photo</span></a>
                <a href='https://www.mailallcenter.com/Passport-Photos' target='_blank'><span><DoneIcon sx={{color:'green'}}/>&nbsp;Passport Renewal</span></a>
                <a href='https://www.mailallcenter.com/Mailbox-Rental' target='_blank'><span><DoneIcon sx={{color:'green'}}/>&nbsp;Mail Box Rental</span></a>
                <a href='https://www.mailallcenter.com/Pack-Ship/Shipping' target='_blank'><span><DoneIcon sx={{color:'green'}}/>&nbsp;Pack & Ship</span></a>
            </div>
          </div>
        </div>
      </div>
    <div className="apostille-container">
      
      <section className="steps">
        <h1>Our Step-by-Step Process</h1>
        <div className="step">
          <img src="/images/aboutus.jpg" alt="Consultation" />
          <h3>Step 1: Consultation and Document Assessment</h3>
          <p>
            Contact us to discuss your needs, whether it's an apostille for a birth certificate, death certificate, or other documents.
          </p>
        </div>
        <div className="step">
          <h3>Step 2: Document Submission</h3>
          <p>
            Submit your documents in person or via mail. We're your trusted local partner for apostille services in San Jose and Santa Clara County.
          </p>
          <img src="/images/aboutus.jpg" alt="Document Submission" />

        </div>
        <div className="step">
          <img src="/images/aboutus.jpg" alt="Processing" />
          <h3>Step 3: Seamless Processing</h3>
          <p>
            Our team ensures quick and accurate processing of your documents for hassle-free apostille certification.
          </p>
        </div>
        <div className="step">
          <h3>Step 4: Document Pickup or Delivery</h3>
          <p>
            Collect your certified documents in person or have them mailed to your address.
          </p>
          <img src="/images/aboutus.jpg" alt="Delivery" />

        </div>
      </section>

      <section className="why-choose-us">
        <h1>Why Choose Live Scan Solutions?</h1>
        <ul>
          <li>Expertise in apostille services for accurate and timely certifications.</li>
          <li>Local convenience in San Jose and Santa Clara County.</li>
          <li>Comprehensive solutions for various document types.</li>
          <li>Efficient processing for prompt results.</li>
          <li>Customer-centric approach ensuring a smooth experience.</li>
        </ul>
      </section>

      <section className="additional-services">
        <h2>Other Services You Might Need</h2>
        <p>
          Some documents may require notarization, translation, or signature authentication before an apostille. We provide these services to simplify the process.
        </p>
      </section>

      <section className="Faq">
        <h1>Frequently Asked Questions</h1>
        <div className="accordion">
          <Accordion className='accordion_item'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>How do I know if I need an Apostille?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                The Consulate of the country you are corresponding with will inform you if your documents require an Apostille.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className='accordion_item'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>What countries require an Apostille?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Countries part of the Hague Convention require an Apostille. View the full list <a href="#countries">here</a>.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className='accordion_item'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>What if the country is not part of the Hague Convention?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Additional authentications and paperwork may be required. We will guide you through the necessary steps.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
      </section>

      <footer className="footer">
        <h2>Make An Appointment</h2>
        <p>Contact Live Scan Solutions today to simplify your apostille process.</p>
      </footer>
    </div>
    </>
  );
};

export default ApostilleServices;
